import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Gallery from "../components/photoAlbum/Gallery"
import SEO from "../components/SEO"
import Layout from "../components/layout"
import AlbumCover from "../components/photoAlbum/AlbumCover"

const Media = ({ data }) => {
  const albums = data.allSanityAlbum.edges
  return (
    <Layout>
      <SEO title="Media" />
      <div className=" bg-gray-300 pt-24 md:pt-16 ">
        {/* <div className="fixed right-0 left-0 mr-auto ml-auto h-auto w-auto inline">
        Albums:{" "}
        <ul>
          {albums.map(album => (
            <li>
              <a href={`#${album.node.slug.current}`}>{album.node.title}</a>
            </li>
          ))}
        </ul>
      </div> */}
        <h1 className="text-6xl text-center pt-10">Photos & Videos</h1>
        <div className="flex gap-x-10 gap-y-10 justify-center flex-wrap mt-10 pb-20 ">
          {(albums.length > 0 &&
            albums.map(({ node: album }, index) => {
              {
                /* Use  albumCover if provided in CMS, or use first image in array as cover */
              }
              const albumCover = album.albumCover
                ? album.albumCover.asset.fluid
                : album.imageSet[0].asset.fluid

              {
                /* Add opaque box shadow https://tailwindcss.com/docs/box-shadow */
              }

              {
                /* tailwind hover transition adapted from Tailwind docs: https://tailwindcss.com/docs/transition-property  */
              }

              return (
                <AlbumCover
                  slug={album.slug.current}
                  title={album.title}
                  fluid={albumCover}
                  className="mt-5"
                  index={index}
                />
              )
            })) || <div>No albums yet. Check back soon!</div>}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MediaPageQuery {
    allSanityAlbum {
      edges {
        node {
          title
          description
          slug {
            current
          }
          _rawImageSet(resolveReferences: { maxDepth: 10 })
          imageSet {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          albumCover {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`

export default Media
